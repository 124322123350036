import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import _ from 'lodash';

export default function ApplyFilter({
  name,
  setName,
  course,
  courseSelect,
  setCourseSelect,
  level,
  levelSelect,
  setLevelSelect,
}) {
  return (
    <div>
      <div className="w-full my-2">
        <Card>
          <div className="flex gap-2 ">
            <div className="m-2 w-full">
              <TextField
                label="ค้นหา"
                fullWidth
                size="small"
                onChange={(e) => setName(e.target.value)}
              ></TextField>
            </div>
            <div className="m-2 w-full">
              <FormControl fullWidth>
                <InputLabel>ชั้นเรียน</InputLabel>
                <Select
                  value={levelSelect}
                  label="ชั้นเรียน"
                  size="small"
                  onChange={(e) => setLevelSelect(e.target.value)}
                >
                  <MenuItem value={''}>ทั้งหมด</MenuItem>
                  {_?.map(level?.rows, (e) => (
                    <MenuItem value={e?._id}>{e?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="m-2 w-full">
              <FormControl fullWidth>
                <InputLabel>คอร์เรียน</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={courseSelect}
                  label="คอร์เรียน"
                  size="small"
                  onChange={(e) => setCourseSelect(e.target.value)}
                >
                  <MenuItem value={''}>ทั้งหมด</MenuItem>
                  {_?.map(course?.rows, (e) => (
                    <MenuItem value={e?._id}>{e?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
