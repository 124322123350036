import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@mui/material';
import SubjectForm from 'components/Forms/SubjectForm';

function EditSubject({ title, subtitle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const subject = useSelector((state) => state.subject);
  const level = useSelector((state) => state.level);
  const employee = useSelector((state) => state.employee);
  const course = useSelector((state) => state.course);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(actions.subjectGet(id));
    dispatch(actions.levelAll({}));
    dispatch(actions.employeeAll({}));
    dispatch(actions.courseAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    setValue('subjectId', subject?.subjectId);
    setValue('name', subject?.name);
    setValue('description', subject?.description);
    setValue('price', subject?.price);
    setValue('level', subject?.level);
    setValue('course', subject?.course);
    setValue('teacher', subject?.teacher);
    return () => {};
  }, [subject, level]);

  const onSubmit = async (data) => {
    // console.log('data', data);
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.subjectPut(id, data));
      await dispatch(actions.subjectAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <div className="py-1">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubjectForm
          control={control}
          Controller={Controller}
          course={course}
          employee={employee}
          level={level}
          subject={subject}
        />
      </form>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      {renderForm()}
    </div>
  );
}

EditSubject.propTypes = {};

export default EditSubject;
