import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
export default function ExpensesApplyForm({
  control,
  Controller,
  date,
  setDate,
  setPaidType,
  paidType,
}) {
  const handleChange = (event) => {
    setPaidType(event.target.value);
  };

  return (
    <div>
      {' '}
      <div className="w-full px-1 py-1">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="วันที่"
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'name'}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายการ"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'price'}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนเงิน"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">วิธีชำระเงิน</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paidType}
            label="วิธีชำระเงิน"
            size="small"
            onChange={handleChange}
          >
            <MenuItem value={'TRANSFER'}>โอนเงิน</MenuItem>
            <MenuItem value={'CASH'}>เงินสด</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="flex justify-center">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}
