import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import CourseTable from 'components/Table/CourseTable';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CourseForm from 'components/Forms/CourseForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function CourseList({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const course = useSelector((state) => state.course);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const onOpen = async (data, id) => {
    await dispatch(actions.courseEPut(id, { status: data }));
    await dispatch(actions.courseAll({}));
  };

  useEffect(() => {
    dispatch(actions.courseAll({ page, size }));
    return () => {};
  }, [page, size]);

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.courseDelete(id));
        dispatch(actions.courseAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    history.push(`/subject/course/edit/${id}`);
  };

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.courseCreate(data));
      await dispatch(actions.courseAll({}));
      reset();
      handleClose();
    }
  };

  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            เพิ่มคอร์ส
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CourseForm control={control} Controller={Controller} />
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderTable = () => (
    <CourseTable
      course={course}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      onOpen={onOpen}
    />
  );

  const renderAddButton = () => (
    <div className="flex justify-end py-1">
      <Button variant="contained" onClick={handleOpen}>
        เพิ่ม
      </Button>
    </div>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      {renderAddButton()}
      {renderTable()}
      {renderModal()}
    </div>
  );
}

CourseList.propTypes = {};

export default CourseList;
