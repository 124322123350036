import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button } from '@mui/material';
import SubjectTable from 'components/Table/SubjectTable';

function SubjectList({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const subject = useSelector((state) => state.subject);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    dispatch(actions.subjectAll({}));

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderAddButton = () => (
    <Button variant="contained" onClick={() => history.push('subject/new')}>
      เพิ่ม
    </Button>
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.subjectDelete(id));
        dispatch(actions.subjectAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    history.push(`/subject/subject/edit/${id}`);
  };

  const renderTable = () => (
    <SubjectTable
      subject={subject}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex justify-end">{renderAddButton()}</div>
      {renderTable()}
    </div>
  );
}

SubjectList.propTypes = {};

export default SubjectList;
