import {
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { PAIDTYPE_STATUS } from 'utils/constants';

export default function ApplyDetailCard({
  apply,
  handleOpen,
  handleDeleteRevenue,
  handleOpenEdit,
  subject,
  Invoice,
}) {
  return (
    <div>
      <div className="py-1">
        <Card>
          <div className="p-2 text-xl">
            <div className="font-bold">ข้อมูลนักเรียน</div>
            <div>
              ชื่อ-สกุล : {apply?.customer?.firstname}{' '}
              {apply?.customer?.lastname}
            </div>
            <div>ชื่อเล่น : {apply?.customer?.nickname}</div>
            <div>เบอร์โทร : {apply?.customer?.tel}</div>
          </div>
        </Card>
      </div>
      <div>
        <Card>
          <div className="p-2 text-xl">
            <div className="font-bold flex justify-between">
              ข้อมูลสมัครเรียน{' '}
              <div>
                <Button
                  color="warning"
                  size="small"
                  variant="contained"
                  onClick={() => handleOpenEdit()}
                >
                  แก้ไข
                </Button>
              </div>{' '}
            </div>
            <div>คอร์สเรียน : {apply?.course?.name}</div>
            <div>
              <Table>
                <TableHead>
                  <TableRow className="bg-gray-200 ">
                    <TableCell>
                      {' '}
                      <h1 className="font-bold "> ลำดับ</h1>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <h1 className="font-bold "> ชื่อวิชา</h1>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <h1 className="font-bold "> ราคา</h1>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(apply?.order, (e, index) => (
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{e?.name}</TableCell>
                      <TableCell>
                        {e?.price
                          ?.toFixed(2)
                          ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {' '}
                    <h1 className="font-bold "> รวม</h1>
                  </TableCell>
                  <TableCell>
                    {apply?.totalPrice
                      ?.toFixed(2)
                      ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </TableCell>
                </TableRow>
              </Table>
            </div>
          </div>
        </Card>
      </div>
      <div className="py-1">
        <Card>
          <div className="p-2 text-xl">
            <div className="flex gap-2 ">
              <div className="font-bold">ข้อมูลชำระเงิน</div>
              <div>
                <Button variant="contained" onClick={() => handleOpen()}>
                  ชำระเงิน
                </Button>
              </div>
            </div>
            <div className="lg:flex gap-4 py-2">
              <div>
                <div>
                  ค่าเรียนรวม :{' '}
                  {apply?.totalPrice
                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </div>
                <div>
                  ส่วนลดรวม :{' '}
                  {apply?.discountTotal

                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </div>
              </div>
              <div>
                <div className="font-bold text-green-600">
                  ชำระแล้ว :{' '}
                  {apply?.paidTotal

                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </div>
                <div className="font-bold text-red-600">
                  ค้างจ่าย :{' '}
                  {apply?.overdue
                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 ">
            <div className="font-bold">รายการชำระเงิน</div>
            {_.map(apply?.paidOrder, (e, index) => (
              <div className="py-1">
                <Card>
                  <div className="p-2 text-s">
                    <div className="flex justify-between">
                      ครั้งที่ {index + 1}{' '}
                      <div>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => Invoice(e, apply)}
                        >
                          พิมพ์
                        </Button>
                        <Button onClick={() => handleDeleteRevenue(e?._id)}>
                          ลบ
                        </Button>
                      </div>{' '}
                    </div>
                    <div>
                      เลขที่ใบเสร็จ {e?.prefix}
                      {e?.runningNumber}
                    </div>
                    <div>วันที่ {dayjs(e?.date).format('DD/MM/YY')}</div>
                    <div>
                      จำนวนเงิน {e?.price} บาท (
                      {PAIDTYPE_STATUS?.[e?.paidType]?.description}){' '}
                    </div>
                    <div>ส่วนลด {e?.discount} บาท</div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
}
