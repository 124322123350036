import { Button, Card, TextField } from '@mui/material';
import React from 'react';

export default function ProjectForm({ control, Controller, project }) {
  return (
    <Card>
      <div className="p-2">
        <div className="w-full px-1 py-1">
          <Controller
            name={'projectNumber'}
            control={control}
            defaultValue={project ? project.projectNumber : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รหัสโปรเจค"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'name'}
            control={control}
            defaultValue={project ? project.name : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อโปรเจค"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'description'}
            control={control}
            defaultValue={project ? project.description : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="คำอธิบายเพิ่มเติม"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="flex justify-center">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </div>
    </Card>
  );
}
