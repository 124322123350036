import { Button, TextField } from '@mui/material';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import React from 'react';

export default function LevelForm({
  control,
  Controller,
  level,
  addedImage,
  setAddedImage,
}) {
  return (
    <div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'levelId'}
          control={control}
          defaultValue={level ? level.levelId : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสชั้น"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'name'}
          control={control}
          defaultValue={level ? level.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ระดับชั้น"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div>
        <div className="w-full px-1 py-2">
          <ImageUpload
            preview_size="250"
            maxNumber={1}
            images={addedImage}
            setImages={setAddedImage}
            title={'อัพโหลดรูปภาพ'}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}
