import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { StudentApply, ApplyDetail, SuccessPage } from '../views/Student';
import { AuthNavbar, HomeNavbar } from '../components/Nevbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';
import { PersonalProfile } from '../views/Profile';

export function Student() {
  const module = 'STUDENT';
  const prefix = '/student';
  const name = 'สมัครเรียน';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <div className="min-h-screen">
      <AuthNavbar />

      <div className="relative  min-h-screen  pt-16 px-8 ">
        <div className="py-4">
          <Switch>
            <Route exact path={`${prefix}/apply`}>
              <StudentApply title="สมัครเรียน" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/list`}>
              <ApplyDetail title="ค้นหาข้อมูล" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/apply/success`}>
              <SuccessPage title="การสมัครเสร็จสิ้น" subtitle={name} />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Student;
