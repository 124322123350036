import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';
import { Button } from '@mui/material';
import ProjectTable from 'components/Forms/Table/ProjectTable';

function ProjectList({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector((state) => state.project);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    dispatch(actions.projectAll({}));
    return () => {};
  }, []);

  const renderAddButton = () => (
    <Button variant="contained" onClick={() => history.push('/project/new')}>
      เพิ่มโปรเจค
    </Button>
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.projectDelete(id));
        dispatch(actions.projectAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    history.push(`/project/edit/${id}`);
  };

  const renderProject = () => (
    <div className="py-1">
      <ProjectTable
        project={project}
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  if (!project?.isLoading && project?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-end">{renderAddButton()}</div>
        {renderProject()}
      </div>
    );
  } else {
    return <Loading isLoading />;
  }
}

ProjectList.propTypes = {};

export default ProjectList;
