import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import _ from 'lodash';

export default function CardCourse({ course, courseSelect, setCourseSelect }) {
  return (
    <div>
      <div className="w-full">
        <Card>
          <div className="flex gap-2 ">
            <div className="m-1 w-full">
              <FormControl fullWidth>
                <InputLabel>คอร์เรียน</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={courseSelect}
                  label="คอร์เรียน"
                  size="small"
                  onChange={(e) => setCourseSelect(e.target.value)}
                >
                  <MenuItem value={''}>ทั้งหมด</MenuItem>
                  {_?.map(course?.rows, (e) => (
                    <MenuItem value={e?._id}>{e?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
