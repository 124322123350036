import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import _ from 'lodash';

function ApplyDetail({ title, subtitle }) {
  const dispatch = useDispatch();
  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const apply = useSelector((state) => state.apply);
  const std = apply?.rows?.[0];

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(actions.applyReset());
    dispatch(
      actions.applyAll({
        firstname: data?.firstname,
        lastname: data?.lastname,
        tel: data?.tel,
        check: true,
      }),
    );
  };

  const renderForm = () => (
    <div className="flex content-center items-center justify-center h-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2">ค้นหาข้อมูล</div>
        <div className="lg:flex">
          <div className="lg:w-2/3 px-1 py-1">
            <Controller
              name={'prefix'}
              control={control}
              defaultValue={''}
              required={true}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="คำนำหน้า"
                  fullWidth
                  size={'small'}
                  required={true}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-1">
            <Controller
              name={'firstname'}
              control={control}
              defaultValue={''}
              required={true}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อจริง"
                  fullWidth
                  size={'small'}
                  required={true}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-1">
            <Controller
              name={'lastname'}
              control={control}
              defaultValue={''}
              required={true}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="นามสกุล"
                  fullWidth
                  required={true}
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-1">
            <Controller
              name={'tel'}
              control={control}
              defaultValue={''}
              required={true}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="เบอร์โทร"
                  fullWidth
                  required={true}
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="py-1 ">
            <Button variant="contained" type="submit">
              ค้นหา
            </Button>
          </div>
        </div>
        <div>{renderList()}</div>
      </form>
    </div>
  );

  const renderList = () => (
    <div className="py-4">
      <div>
        <div>
          {_?.isEmpty(apply?.rows) ? (
            <div>
              <Card>
                <div className="p-2 flex justify-center">ไม่มีรายการ</div>
              </Card>
            </div>
          ) : (
            <div>
              <Card>
                <div className="p-2">
                  <div>รายการ</div>
                  <div>
                    ชื่อ-สกุล: {std?.customer?.firstname}{' '}
                    {std?.customer?.lastname}
                  </div>
                </div>
                {_?.map(apply?.rows, (each, index) => (
                  <div className="px-2 py-1">
                    <Card>
                      <div className="p-2">
                        <div>
                          คอร์ส : {each?.course?.name} ({each?.level?.name}){' '}
                        </div>
                        <div className="flex">
                          วิชาที่สมัคร :
                          <div>
                            {_?.map(each?.order, (e, index) => (
                              <div>
                                {' '}
                                {e?.name} (
                                {e?.price
                                  ?.toFixed(2)
                                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                )
                              </div>
                            ))}
                          </div>
                        </div>
                        <div>
                          จำนวนเงินรวม :{' '}
                          {each?.totalPrice
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div>
                          ชำระแล้ว :{' '}
                          {(each?.paidTotal + each?.discountTotal)
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div>
                          ค้างจ่าย :{' '}
                          {each?.overdue
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}{' '}
              </Card>
            </div>
          )}
          <div></div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {/* {renderViewTitle()} */}
      {renderForm()}
      {/* {renderList()} */}
    </div>
  );
}

ApplyDetail.propTypes = {};

export default ApplyDetail;
