import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from 'react-feather';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function SuccessPage({ title, subtitle }) {
  const history = useHistory();
  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 font-bold">การสมัครเสร็จสิ้น</h6>
                  <h6 className=" flex justify-center  text-green-700 py-4">
                    <CheckCircle size={120} />{' '}
                  </h6>
                  <h1>สามารถชำระเงินได้ที่โรงเรียนกวดวิชา PST</h1>
                  <h1>หรือติดต่อ 085-5808430</h1>
                  <Button
                    variant="contained"
                    onClick={() => history.push('/student/apply')}
                  >
                    กลับสู่หน้าหลัก
                  </Button>
                </div>
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SuccessPage.propTypes = {};

export default SuccessPage;
