import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { BackButton } from 'components/Button';
import ApplyDetailCard from 'components/Card/ApplyDetailCard';
import { Box, Modal, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import RevenueApplyForm from 'components/Forms/RevenueApplyForm';
import Loading from 'components/Loading';
import EditApplyForm from 'components/Forms/EditApplyForm';
import { Invoice } from 'components/PDF';
import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function ApplyDetail({ title, subtitle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const apply = useSelector((state) => state.apply);
  const subject = useSelector((state) => state.subject);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [date, setDate] = useState(new Date());
  const [paidType, setPaidType] = React.useState('TRANSFER');
  const [subjectSelect, setSubjectSelect] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  useEffect(() => {
    dispatch(actions.applyReset());
    dispatch(actions.applyGet(id));
    return () => {};
  }, []);

  useEffect(() => {
    setSubjectSelect(apply?.order);
    dispatch(
      actions.subjectAll({
        course: apply?.course?._id,
        level: apply?.level?._id,
      }),
    );

    return () => {};
  }, [apply]);

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        apply: id,
        date,
        paidType,
        prefix: `RE${dayjs().format('DDMMYYYY')}`,
      };
      await dispatch(actions.revenueCreate(dataSubmit));
      await dispatch(actions.applyGet(id));
      reset();
      handleClose();
    }
  };
  const onUpdate = async () => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        order: subjectSelect,
        totalPrice: _.sumBy(subjectSelect, 'price'),
      };

      await dispatch(actions.applyPut(id, dataSubmit));
      await dispatch(actions.applyGet(id));
      // reset();
      handleCloseEdit();
    }
  };

  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ชำระเงิน
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RevenueApplyForm
              control={control}
              Controller={Controller}
              date={date}
              setDate={setDate}
              paidType={paidType}
              setPaidType={setPaidType}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderModalEdit = () => (
    <div>
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            แก้ไข
            <div>
              <EditApplyForm
                subjectSelect={subjectSelect}
                subject={subject}
                setSubjectSelect={setSubjectSelect}
                onUpdate={onUpdate}
              />
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );

  const handleDeleteRevenue = async (eid) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.revenueDelete(eid));
      await dispatch(actions.applyGet(id));
      // reset();
      // handleClose();
    }
  };

  const renderCard = () => (
    <ApplyDetailCard
      apply={apply}
      handleOpen={handleOpen}
      handleDeleteRevenue={handleDeleteRevenue}
      handleOpenEdit={handleOpenEdit}
      Invoice={Invoice}
    />
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  if (!apply?.isLoading && apply?.isCompleted) {
    return (
      <div>
        {renderModalEdit()}
        {renderModal()}
        {renderTitle()}
        <BackButton />
        {renderCard()}
      </div>
    );
  } else {
    return <Loading isLoading />;
  }
}

ApplyDetail.propTypes = {};

export default ApplyDetail;
