import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import { useForm, Controller } from 'react-hook-form';
import CustomerForm from 'components/Forms/CustomerForm';
import { BackButton } from 'components/Button';

function NewCustomer({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [addedEmployeeImage, setAddedEmployeeImage] = useState([]);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    // console.log('data', data);
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      if (!_.isEmpty(addedEmployeeImage)) {
        // eslint-disable-next-line no-param-reassign
        data.image = {
          image: addedEmployeeImage[0]?.data_url,
          imageType: 'profile',
          alt: '',
        };
      }
      await dispatch(actions.customerCreate(data));
      await dispatch(actions.customerAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <div className="py-1">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomerForm
          employeeImage={addedEmployeeImage}
          setEmployeeImage={setAddedEmployeeImage}
          control={control}
          Controller={Controller}
        />
      </form>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <BackButton />
      {renderForm()}{' '}
    </div>
  );
}

NewCustomer.propTypes = {};

export default NewCustomer;
