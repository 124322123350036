import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button } from '@mui/material';
import _ from 'lodash';
import ApplyStudentTable from 'components/Table/ApplyStudentTable';
import ApplyStudentFilter from 'components/Card/ApplyStudentFilter';
import StudentApplySubject from 'components/PDF/StudentApplySubject';

function StudentListWithSubject({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const apply = useSelector((state) => state.apply);
  const course = useSelector((state) => state.course);
  const level = useSelector((state) => state.level);
  const subject = useSelector((state) => state.subject);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState('');
  const [courseSelect, setCourseSelect] = useState('');
  const [levelSelect, setLevelSelect] = useState('');
  const [subjectSelect, setSubjectSelect] = useState('');
  const [subjectFind, setSubjectFind] = useState('');

  useEffect(() => {
    dispatch(actions.courseAll({}));
    dispatch(actions.levelAll({}));

    return () => {};
  }, []);

  const findSubject = (data) => {
    const subjects = _?.find(subject?.rows, (e) => e?._id === subjectSelect);
    setSubjectFind(subjects);
  };

  console.log(subjectFind);

  useEffect(() => {
    dispatch(actions.subjectAll({ course: courseSelect, level: levelSelect }));
    return () => {};
  }, [levelSelect, courseSelect]);

  useEffect(() => {
    dispatch(
      actions.applyAll({
        name,
        courseSelect,
        levelSelect,
        subjectSelect,
      }),
    );
    findSubject();
    return () => {};
  }, [name, page, size, courseSelect, levelSelect, subjectSelect]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderAddButton = () => (
    <div className="lg:flex justify-between gap-2 ">
      <div className="w-full">{renderFilter()}</div>
      <div className="w-1/4 py-4 flex lg:justify-end ">
        <div className="flex gap-2">
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={() => StudentApplySubject(apply, subjectFind)}
          >
            พิมพ์
          </Button>
        </div>
      </div>
    </div>
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.applyDelete(id));
        dispatch(actions.applyAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    // history.push(`/subject/course/edit/${id}`);
  };

  const handleDetail = (id) => {
    history.push(`/apply/apply/detail/${id}`);
  };

  const renderFilter = () => (
    <div>
      <ApplyStudentFilter
        setName={setName}
        course={course}
        courseSelect={courseSelect}
        setCourseSelect={setCourseSelect}
        level={level}
        levelSelect={levelSelect}
        setLevelSelect={setLevelSelect}
        subject={subject}
        subjectSelect={subjectSelect}
        setSubjectSelect={setSubjectSelect}
      />
    </div>
  );

  const renderTable = () => (
    <ApplyStudentTable
      apply={apply}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDetail={handleDetail}
    />
  );

  return (
    <div>
      {renderTitle()}
      {renderAddButton()}
      {renderTable()}
    </div>
  );
}

StudentListWithSubject.propTypes = {};

export default StudentListWithSubject;
