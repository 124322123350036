import { Button, TextField } from '@mui/material';
import React from 'react';

export default function CourseForm({ control, Controller, course }) {
  return (
    <div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'courseNumber'}
          control={control}
          defaultValue={course ? course.courseNumber : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสคอร์ส"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'name'}
          control={control}
          defaultValue={course ? course.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อคอร์ส"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="flex justify-center">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}
