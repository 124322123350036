import React from 'react';

import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';

export default function ApplyTable({
  apply,
  page,
  size,
  setPage,
  setSize,
  handleEdit,
  handleDelete,
  handleDetail,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  return (
    <div>
      <div className="text-xl">
        <Paper>
          <TableContainer component={Paper}>
            <Table>
              {/* <colgroup>
              <col width="90%" />
              <col width="10%" />
            </colgroup> */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h1 className="font-bold  "> ลำดับ</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold "> ชื่อ-นามสกุล</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold "> ชั้นเรียน</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold ">คอร์สเรียน</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold ">วิชาที่เรียน</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold ">ราคา</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold ">ชำระแล้ว</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold ">ค้างจ่าย</h1>
                  </TableCell>

                  <TableCell>
                    <h1 className="font-bold"> ดำเนินการ</h1>
                  </TableCell>
                </TableRow>
              </TableHead>

              {_.isEmpty(apply?.rows) ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      ไม่มีรายการ
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {apply?.rows?.map((e, index) => (
                    <TableRow key={e._id}>
                      <TableCell>
                        <h1 className="">{index + 1}</h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="">
                          {e?.customer?.prefix} {e?.customer?.firstname}{' '}
                          {e?.customer?.lastname}{' '}
                        </h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="">{e?.level?.name}</h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="">{e?.course?.name}</h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="">
                          {_?.map(e?.order, (e, index) => (
                            <div>
                              {' '}
                              {index + 1} {e?.name}
                            </div>
                          ))}
                        </h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="">
                          {e?.totalPrice
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="">
                          {e?.paidTotal
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </h1>
                        <h1 className="">
                          ส่วนลด{' '}
                          {e?.discountTotal
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="">
                          {e?.overdue
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </h1>
                      </TableCell>

                      <TableCell align="">
                        <div className="flex gap-1   ">
                          <Button
                            onClick={() => handleDetail(e._id)}
                            color="success"
                            size="small"
                            variant="contained"
                          >
                            รายละเอียด
                          </Button>

                          <Button
                            onClick={() => handleDelete(e._id)}
                            color="error"
                            size="small"
                            disabled={!_.isEmpty(e?.paidOrder)}
                            variant="contained"
                          >
                            ลบ
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 100, 1000]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={apply?.total || 0}
            rowsPerPage={size}
            page={page - 1}
          />
        </Paper>
      </div>
    </div>
  );
}
