import React from 'react';

import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import { PAIDTYPE_STATUS } from 'utils/constants';

export default function ExpensesTable({
  expenses,
  page,
  size,
  setPage,
  setSize,
  handleEdit,
  handleDelete,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            {/* <colgroup>
                <col width="90%" />
                <col width="10%" />
              </colgroup> */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold  text-center "> ลำดับ</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center "> วันที่</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">รายการจ่าย</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">จำนวนเงิน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">วิธีชำระเงิน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center "> ดำเนินการ</h1>
                </TableCell>
              </TableRow>
            </TableHead>

            {_.isEmpty(expenses?.rows) ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    ไม่มีรายการ
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {expenses?.rows?.map((e, index) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className=" text-center ">{index + 1}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">
                        {dayjs(e?.date).format('DD/MM/YYYY')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="text-center">{e?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.price}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">
                        {PAIDTYPE_STATUS?.[e?.paidType]?.description}
                      </h1>
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex gap-1 justify-center  ">
                        <Button
                          onClick={() => handleEdit(e._id)}
                          color="warning"
                          variant="contained"
                        >
                          แก้ไข
                        </Button>
                        <Button
                          onClick={() => handleDelete(e._id)}
                          color="error"
                          size="small"
                          variant="contained"
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={expenses?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
