import { Card, TextField } from '@mui/material';
import React from 'react';

export default function NameFillter({ setName }) {
  return (
    <div className="w-full">
      <Card>
        <div className="m-2 ">
          <TextField
            label="ค้นหา"
            fullWidth
            size="small"
            onChange={(e) => setName(e.target.value)}
          ></TextField>
        </div>
      </Card>
    </div>
  );
}
