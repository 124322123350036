import { Button, Card, TextField } from '@mui/material';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import React from 'react';

export default function CustomerForm({
  control,
  Controller,
  employeeImage,
  setEmployeeImage,
  customer,
}) {
  return (
    <Card>
      <div className="p-2">
        <div className="w-full px-1 py-1">
          <Controller
            name={'prefix'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="คำนำหน้า"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'firstname'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อจริง"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'lastname'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="นามสกุล"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'nickname'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อเล่น"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'tel'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                label="เบอร์โทร"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div>
          <div className="w-full px-1 py-2">
            <ImageUpload
              preview_size="250"
              maxNumber={1}
              images={employeeImage}
              setImages={setEmployeeImage}
              title={customer?.rows ? 'อัพโหลดรูปภาพ' : 'แก้ไขรูปภาพ'}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </div>
    </Card>
  );
}
