import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import { useForm, Controller } from 'react-hook-form';
import * as actions from '../../redux/actions';
import { useHistory } from 'react-router';
import ProjectForm from 'components/Forms/ProjectForm';

function NewProject({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.projectCreate(data));
      await dispatch(actions.projectAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <div className="py-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProjectForm control={control} Controller={Controller} />
      </form>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {renderBackButton()}
      {renderForm()}
    </div>
  );
}

NewProject.propTypes = {};

export default NewProject;
