import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Autocomplete,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

export default function ApplyForm({
  control,
  Controller,
  setValue,
  customer,
  course,
  level,
  levelSelect,
  setCourseSelect,
  setLevelSelect,
  subject,
  fields,
  append,
  remove,
  courseSelect,
  setSubjectSelect,
  subjectSelect,
}) {
  const [reload, setReload] = useState(false);
  const handleCheckCustomer = (data, index) => {
    const customers = _.find(customer.rows, { _id: data?._id });
    setValue(`customer`, customers?._id);
    setValue(`tel`, customers?.tel);
  };
  const handleCheckLevel = (data, index) => {
    const each = _.find(level.rows, { _id: data?._id });
    setValue(`level`, each?._id);
    setLevelSelect(each?._id);
  };
  const handleCheckCourse = (data, index) => {
    const each = _.find(course.rows, { _id: data?._id });
    setValue(`course`, each?._id);
    setCourseSelect(each?._id);
  };

  const selectSubject = (data) => {
    subjectSelect.push(data);
    setSubjectSelect(subjectSelect);
    setReload(!reload);
  };

  const deleteSubject = (id) => {
    const index = _.findIndex(subjectSelect, (e) => e?._id === id?._id);
    const NewArrayArray = [...subjectSelect];
    NewArrayArray.splice(index, 1);
    setSubjectSelect(NewArrayArray);
  };

  const checkDisable = (id) => {
    const checked = _.find(subjectSelect, (e) => e?._id === id);
    if (checked) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    console.log('reload');

    return () => {};
  }, [reload]);

  return (
    <div className="p-2">
      <div className="py-1 font-bold">เลือกนักเรียน</div>
      <div className="w-full px-1 py-1">
        <Controller
          name={`customer`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              id="free-solo-demo"
              freeSolo
              options={customer?.rows || []}
              rules={{ required: true }}
              getOptionLabel={(option) =>
                `${option.prefix || '-'} ${option.firstname} ${option.lastname}`
              }
              onChange={(e, newValue) => handleCheckCustomer(newValue)}
              renderInput={(params) => (
                <TextField
                  {...field}
                  {...params}
                  size="small"
                  label="นักเรียน"
                  required
                />
              )}
            />
          )}
        />{' '}
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'tel'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField {...field} label="เบอร์โทร" fullWidth size={'small'} />
          )}
        />
      </div>

      <div className="py-1 font-bold">เลือกคอร์ส</div>
      <div className="w-full px-1 py-1">
        <Controller
          name={`course`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              id="free-solo-demo"
              freeSolo
              options={course?.rows || []}
              getOptionLabel={(option) =>
                `${option.courseNumber} ${option.name}`
              }
              onChange={(e, newValue) => handleCheckCourse(newValue)}
              renderInput={(params) => (
                <TextField
                  {...field}
                  {...params}
                  size="small"
                  label="คอร์สเรียน"
                />
              )}
            />
          )}
        />{' '}
      </div>
      {courseSelect && <div className="py-1 font-bold">เลือกระดับชั้น</div>}
      {courseSelect && (
        <div className="w-full px-1 py-1">
          <Controller
            name={`level`}
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                id="free-solo-demo"
                freeSolo
                options={level?.rows || []}
                getOptionLabel={(option) =>
                  `${option.levelId} | ${option.name}`
                }
                onChange={(e, newValue) => handleCheckLevel(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...field}
                    {...params}
                    size="small"
                    label="ระดับชั้น"
                  />
                )}
              />
            )}
          />{' '}
        </div>
      )}

      {levelSelect && <div className="py-1 font-bold">เลือกวิชา</div>}
      {levelSelect && (
        <div>
          {_.isEmpty(subject?.rows) ? (
            <Card>
              <div className="p-2">
                <div className="font-bold text-center">ไม่มีวิชาที่เปิดสอน</div>
              </div>
            </Card>
          ) : (
            <div className="py-1 lg:grid grid-cols-3 gap-2">
              {_.map(subject?.rows, (each, index) => (
                <Card>
                  <div
                    className={
                      checkDisable(each?._id) ? `bg-red-200` : `bg-white`
                    }
                  >
                    <div className="p-2">
                      <div className="flex justify-between">
                        {each?.name}
                        <div>
                          {!checkDisable(each?._id) && (
                            <div>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => selectSubject(each)}
                              >
                                เลือก
                              </Button>
                            </div>
                          )}
                          {checkDisable(each?._id) && (
                            <div>
                              <Button
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={() => deleteSubject(each)}
                              >
                                ลบ
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>รายละเอียด: {each?.description}</div>
                      <div>ราคา: {each?.price}</div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          )}
          <div className="py-1 font-bold">สรุปวิชาเรียนที่สมัคร</div>
          <div>
            {_.isEmpty(subjectSelect) ? (
              <div>ยังไม่เลือกวิชาที่ต้องการ</div>
            ) : (
              <div>
                <Table>
                  <TableHead>
                    <TableRow className="bg-gray-200 ">
                      <TableCell>
                        {' '}
                        <h1 className="font-bold "> ลำดับ</h1>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <h1 className="font-bold "> ชื่อวิชา</h1>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <h1 className="font-bold "> ราคา</h1>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(subjectSelect, (e, index) => (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{e?.name}</TableCell>
                        <TableCell>{e?.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      {' '}
                      <h1 className="font-bold "> รวม</h1>
                    </TableCell>
                    <TableCell>{_.sumBy(subjectSelect, 'price')}</TableCell>
                  </TableRow>
                </Table>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex justify-center py-2">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}
