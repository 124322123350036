import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import { useForm, Controller } from 'react-hook-form';
import StudentApplyForm from 'components/Forms/StudentApplyForm';

function StudentApply({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const course = useSelector((state) => state.course);
  const level = useSelector((state) => state.level);
  const subject = useSelector((state) => state.subject);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const [courseSelect, setCourseSelect] = useState();
  const [levelSelect, setLevelSelect] = useState('');
  const [subjectSelect, setSubjectSelect] = useState([]);

  useEffect(() => {
    dispatch(actions.courseAll({ checkStatus: true }));
    dispatch(actions.levelAll({ checkStatus: true }));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(actions.subjectAll({ course: courseSelect, level: levelSelect }));

    return () => {};
  }, [courseSelect, levelSelect]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    console.log('data', data);
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      if (!_?.isEmpty(subjectSelect)) {
        const dataSubmit = {
          ...data,
          order: subjectSelect,
          level: levelSelect,
          course: courseSelect,
          totalPrice: _.sumBy(subjectSelect, 'price'),
        };
        await dispatch(actions.applyOnlineCreate(dataSubmit));
        await dispatch(actions.customerAll({}));
        history.push('/student/apply/success');
      } else {
        alert('กรุณาเลือกวิชาที่ต้องการ');
      }
    }
  };

  const renderForm = () => (
    <div className="py-1">
      <form onSubmit={handleSubmit(onSubmit)}>
        <StudentApplyForm
          control={control}
          Controller={Controller}
          setValue={setValue}
          course={course}
          level={level}
          courseSelect={courseSelect}
          setCourseSelect={setCourseSelect}
          levelSelect={levelSelect}
          setLevelSelect={setLevelSelect}
          subject={subject}
          subjectSelect={subjectSelect}
          setSubjectSelect={setSubjectSelect}
        />
      </form>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {renderForm()}{' '}
    </div>
  );
}

StudentApply.propTypes = {};

export default StudentApply;
