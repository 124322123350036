import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

import _ from 'lodash';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (rows) => {
  const productList = _.map(rows, (row, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: [
        { text: row?.customer?.prefix },
        { text: row?.customer?.firstname },
        { text: '\t' },
        { text: row?.customer?.lastname },
        { text: ` (${row?.customer?.nickname})` },
      ],
    },
    {
      text: row?.customer?.tel,
      alignment: 'right',
    },
    { text: row?.level?.name, alignment: 'center' },
    {
      text: _?.map(row?.order, (e) => `${e?.name}\n`),
    },
    {
      text: row?.totalPrice?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      alignment: 'right',
    },
    {
      text: '',
      alignment: 'right',
    },
  ]);

  return productList;
};

const StudentApply = async (row) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');

  const std = _?.sortBy(row.rows, ['level.name']);
  const totalPrice = _?.sumBy(row.rows, (e) => e?.totalPrice);

  const genItemBody = genBody(std);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 80, 20, 80],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'report ',
    },
    header: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: 'โรงเรียนกวดวิชา พี เอส ที',
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'รายชื่อนักเรียน ',
                style: 'header',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'คอร์ส ',
                style: 'header',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['8%', '28%', '12%', '10%', '20%', '12%', '10%'],
          body: [
            [
              {
                text: 'ลำดับ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ชื่อ-นามสกุล',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'เบอร์โทร',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ชั้นเรียน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วิชาที่เรียน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวนเงิน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: '',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
            [
              {
                text: 'รวมเงิน',
                alignment: 'right',
                colSpan: 5,
                border: [true, true, true, true],
              },
              {
                text: '',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: '',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: '',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: '',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: `${
                  totalPrice?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  ''
                }`,
                fillColor: '#d2d4d2',
                alignment: 'right',
                colSpan: 2,
                border: [true, true, true, true],
              },
              {
                text: '',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 10,
        bold: true,
        alignment: 'center',
      },
      header1: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
      header2: {
        alignment: 'right',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default StudentApply;
